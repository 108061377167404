import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import ContentSection from '/src/components/atoms/ContentSection';

const ContactPage = () => (
  <Layout hideHeader>
    <SEO title="Booking" />
    <ContentSection
      banner
      parallax
      center
      bgSrc="/images/uploads/eoa_grand-rapids.png"
    >
      <h1 className="heading1">Book EoA</h1>
    </ContentSection>
    <ContentSection light>
      <Row center vertGutter>
        <Col width={6}>
          <p>
            All booking inquiries may be sent to{' '}
            <a
              href="mailto:eyeonattraction@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              eyeonattraction@gmail.com
            </a>
            .
          </p>
        </Col>
      </Row>
    </ContentSection>
  </Layout>
);

export default ContactPage;
